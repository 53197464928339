import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../components/ExternalLink';
import LeadParagraph from '../../components/LeadParagraph';
import InternalLink from '../../components/InternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}>{`Resources`}<a parentName="h1" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
    แหล่งข้อมูลเพิ่มเติมสำหรับผู้สนใจระบบ ERPNext
    </LeadParagraph>
    <h2 {...{
      "id": "links--guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Links & guidelines`}<a parentName="h2" {...{
        "href": "#links--guidelines",
        "aria-label": "links  guidelines permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://erpnext.com/" mdxType="ExternalLink">ERPNext Offial Website</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://docs.erpnext.com/docs/v13/user/videos/learn" mdxType="ExternalLink">ERPNext guidelines</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://www.youtube.com/channel/UCn3bV5kx77HsVwtnlCeEi_A" mdxType="ExternalLink"> ERPNext youtube (Offial) </ExternalLink>
      </li>
    </ul>
    <h2 {...{
      "id": "erpnext-repositories",
      "style": {
        "position": "relative"
      }
    }}>{`ERPNext repositories`}<a parentName="h2" {...{
        "href": "#erpnext-repositories",
        "aria-label": "erpnext repositories permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://github.com/frappe/erpnext" mdxType="ExternalLink">ERPNext sourcecode (Module & libraries)</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://brand.hel.fi/en/logo/" mdxType="ExternalLink">Frappe framewotk sourcecode </ExternalLink>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      